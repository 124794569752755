import React from "react"
import styled from "styled-components"

const Container = styled.div`
  padding-right: 10px;
  :last-child {
    padding: none;
  }
  @media (max-width: 550px) {
    padding: 0 0 15px 0;
  }
`
const Title = styled.h4`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 14px;
  color: black;
`
const Details = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 140%;
  @media (max-width: 550px) {
    margin-right: 7px;
  }
`
const GridItem = props => (
  <Container>
    <Title>{props.title}</Title>
    <Details>{props.details}</Details>
  </Container>
)

export default GridItem