import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import ProjectSlideshow from "../components/project-slideshow"
import SEO from "../components/seo"
import GridItem from "../components/projects/grid-item"

//NPM
import Fade from "react-reveal/Fade"
import ProjectsLayout from "../components/projects/projects-layout"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px 3% 20px 20px;
`
const SlideshowContainer = styled.section`
  height: calc(100vh - 32vh);
  width: 100%;
`
const ProjectInfoContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`
const Title = styled.h1`
  text-transform: uppercase;
  margin: 0 20px 0 0;
  @media (max-width: 550px) {
    margin: 0 0 15px 0;
  }
`
const DescriptionContainer = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`
const Description = styled.p`
  margin: 0;
  font-size: 16px;
`

const ExtendedInfoContainer = styled.section`
  padding: 30px 0 0 0;
  flex: 1;
`
const Grid = styled.section`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  @media (max-width: 550px) {
    grid-template-columns: 50% 50%;
  }
`
const Content = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 35px 0;
`
class ProjectPage extends Component {
  constructor(props) {
    super(props)
    this.extendedInfoRef = React.createRef()
    this.projectTitleRef = React.createRef()

    this.state = {
      showMoreInfo: false,
    }
  }

  componentDidMount() {
    this.getActiveCategory()
  }

  getActiveCategory = () => {
    var path = this.props.uri
    path = path.substr(1, path.length)
    const slug = path.split("/")

    return slug[0]
  }

  handleReadMoreClicked = () => {
    this.setState({ showMoreInfo: !this.state.showMoreInfo }, () => {
      if (this.state.showMoreInfo === false) {
        return
      }
      this.projectTitleRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    })
  }
  render() {
    const { project } = this.props.data

    return (
      <ProjectsLayout brand activeCategory={this.getActiveCategory()}>
        <SEO title={project.title} description={project.acf.description} />
        <Container>
          <SlideshowContainer>
            <ProjectSlideshow images={project.acf.gallery} />
          </SlideshowContainer>
          <ProjectInfoContainer>
            <Title ref={this.projectTitleRef}>{project.title}</Title>
            <DescriptionContainer>
              <Description>{project.acf.description}</Description>
            </DescriptionContainer>
          </ProjectInfoContainer>
          <ExtendedInfoContainer ref={this.extendedInfoRef}>
            <Fade bottom distance={"5px"}>
              <Grid>
                <GridItem title="Location" details={project.acf.location} />
                <GridItem
                  title="Beston's Role"
                  details={project.acf.bestons_role}
                />
                <GridItem
                  title="Beston's Team"
                  details={project.acf.bestons_team}
                />
                <GridItem
                  title="Collaborators"
                  details={project.acf.collaborators}
                />
              </Grid>
              <Content dangerouslySetInnerHTML={{ __html: project.content }} />
            </Fade>
          </ExtendedInfoContainer>
        </Container>
      </ProjectsLayout>
    )
  }
}

export default ProjectPage

export const projectQuery = graphql`
  query($id: String!) {
    project: wordpressPost(id: { eq: $id }) {
      title
      content
      acf {
        description
        gallery {
          source_url
          localFile {
            childImageSharp {
              fluid(maxHeight: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        location
        bestons_role
        bestons_team
        collaborators
      }
    }
  }
`