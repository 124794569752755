import React, { useState } from "react"
import Carousel from "nuka-carousel"
import styled from "styled-components"
import Img from "gatsby-image"
import FsLightbox from "fslightbox-react"

const Container = styled.div`
  height: calc(100vh - 32vh);
  width: 100%;
`
const ImageContainer = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  height: calc(100vh - 32vh);
  object-fit: cover;
`

const ProjectSlideshow = props => {
  const [toggler, setToggler] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)

  return (
    
    <Container>
      {props.images && (
        <>
          <Carousel
            autoplay={false}
            wrapAround
            heightMode="current"
            transitionMode="scroll"
            slideIndex={slideIndex}
            afterSlide={slideIndex => setSlideIndex(slideIndex)}
            disableEdgeSwiping={false}
          >
            {props.images.map((image, index) => (
              <ImageContainer onClick={() => setToggler(!toggler)} key={index}>
                <Img
                  style={{
                    width: "100%",
                    height: "100%",
                    margin: 0,
                  }}
                  fadeIn
                  objectFit="cover"
                  fluid={image.localFile.childImageSharp.fluid}
                />
              </ImageContainer>
            ))}
          </Carousel>
          <FsLightbox
            toggler={toggler}
            slide={slideIndex + 1}
            sources={props.images.map(el => el.source_url)}
            type="image"
          />
        </>
      )}
    </Container>
  )
}

export default ProjectSlideshow